import React from 'react';
import { Link } from 'gatsby';
import { Container } from './nav.css';
import FindUsOnFacebook from '../../../images/find-us-on-facebook.svg';

const Nav = () => (
  <Container>
    <ul>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/team">Team</Link>
      </li>
      <li>CALL 404-580-4144</li>
    </ul>
    <br />
    <a href="https://www.facebook.com/northspringgardening/">
      <FindUsOnFacebook style={{ width: '100px' }} />
    </a>
  </Container>
);

export default Nav;
